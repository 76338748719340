<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            >
                <h2 class="headline">Reporte sabanas</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.sede.$model"
                                :error-messages="sedeErrors"
                                label="Sede"
                                :items="sedes"
                                item-text="nombre_sede"
                                item-value="id_sede"
                                :loading="loadSede"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.jornada.$model"
                                :error-messages="jornadaErrors"
                                label="Jornada"
                                :items="jornadas"
                                item-text="nombre_jornada"
                                item-value="id_jornada"
                                :loading="loadJornada"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.level.$model"
                                :error-messages="nivelErrors"
                                label="Nivel"
                                :items="levels"
                                item-text="nombre_nivel"
                                item-value="id_nivel"
                                :loading="loadLevel"
                                @change="getGrades"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.grade.$model"
                                :error-messages="gradeErrors"
                                label="Grado/Carrera"
                                :items="grades"
                                item-text="nombre"
                                item-value="id"
                                :loading="loadGrade"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="1"
                            sm="12"
                            >
                                <v-select
                                label="Sección"
                                :items="sections"
                                item-text="name"
                                item-value="id"
                                v-model="$v.section.$model"
                                :error-messages="sectionErrors"
                                :loading="loadSection"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <section class="d-flex flex-column">
                                    <div>
                                        <v-select
                                        v-model="$v.bimestre.$model"
                                        :error-messages="bimestreErrors"
                                        label="Bimestre"
                                        :items="bimestres"
                                        item-text="name"
                                        item-value="id"
                                        :loading="loadBimestre"
                                        :disabled="finalGrade || recovery"
                                        >
                                        </v-select>
                                    </div>
                                    <div class="lessTop">
                                        <v-checkbox
                                        v-model="finalGrade"
                                        label="Nota final"
                                        :disabled="recovery"
                                        ></v-checkbox>
                                    </div>
                                    <div class="lessTop">
                                        <v-checkbox
                                        v-model="recovery"
                                        label="Recuperación"
                                        :disabled="finalGrade"
                                        ></v-checkbox>
                                    </div>
                                </section>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center mt-2">
                            <div>
                                <v-btn
                                @click="submit"
                                :loading="studentsLoad"
                                class="mr-2"
                                >
                                    Consultar
                                </v-btn>
                            </div>
                            <div>
                                <v-btn
                                @click="exportExcel"
                                :loading="exportLoad"
                                class="mr-2"
                                >
                                    Excel
                                    <v-icon
                                    color="green">
                                        mdi-file-excel
                                    </v-icon>
                                </v-btn>
                            </div>
                            <div>
                                <v-btn
                                @click="exportPdf"
                                :loading="exportLoad"
                                >
                                    PDF
                                    <v-icon
                                    color="red">
                                        mdi-file-pdf
                                    </v-icon>
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card
                v-show="showSabanas"
                >
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                hide-details
                                single-line
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        height="400"
                        fixed-header
                        :headers="headers"
                        :items="students"
                        :search="search"
                        loading=true
                        :loading-text="loadingText"
                        :footer-props="{'items-per-page-options':[-1]}"
                        >
                            <template v-slot:item.course1.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course1)"
                                dark
                                @click="getActivities(item,item.course1)"
                                >
                                    {{item.course1.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course2.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course2)"
                                dark
                                @click="getActivities(item,item.course2)"
                                >
                                    {{item.course2.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course3.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course3)"
                                dark
                                @click="getActivities(item,item.course3)"
                                >
                                    {{item.course3.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course4.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course4)"
                                dark
                                @click="getActivities(item,item.course4)"
                                >
                                    {{item.course4.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course5.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course5)"
                                dark
                                @click="getActivities(item,item.course5)"
                                >
                                    {{item.course5.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course6.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course6)"
                                dark
                                @click="getActivities(item,item.course6)"
                                >
                                    {{item.course6.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course7.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course7)"
                                dark
                                @click="getActivities(item,item.course7)"
                                >
                                    {{item.course7.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course8.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course8)"
                                dark
                                @click="getActivities(item,item.course8)"
                                >
                                    {{item.course8.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course9.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course9)"
                                dark
                                @click="getActivities(item,item.course9)"
                                >
                                    {{item.course9.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course10.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course10)"
                                dark
                                @click="getActivities(item,item.course10)"
                                >
                                    {{item.course10.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course11.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course11)"
                                dark
                                @click="getActivities(item,item.course11)"
                                >
                                    {{item.course11.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course12.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course12)"
                                dark
                                @click="getActivities(item,item.course12)"
                                >
                                    {{item.course12.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course13.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course13)"
                                dark
                                @click="getActivities(item,item.course13)"
                                >
                                    {{item.course13.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course14.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course14)"
                                dark
                                @click="getActivities(item,item.course14)"
                                >
                                    {{item.course14.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course15.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course15)"
                                dark
                                @click="getActivities(item,item.course15)"
                                >
                                    {{item.course15.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course16.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course16)"
                                dark
                                @click="getActivities(item,item.course16)"
                                >
                                    {{item.course16.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course17.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course17)"
                                dark
                                @click="getActivities(item,item.course17)"
                                >
                                    {{item.course17.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course18.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course18)"
                                dark
                                @click="getActivities(item,item.course18)"
                                >
                                    {{item.course18.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course19.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course19)"
                                dark
                                @click="getActivities(item,item.course19)"
                                >
                                    {{item.course19.nota}}
                                </v-chip>
                            </template>
                            <template v-slot:item.course20.nota="{ item }">
                                <v-chip
                                :color="getColor(item.course20)"
                                dark
                                @click="getActivities(item,item.course20)"
                                >
                                    {{item.course20.nota}}
                                </v-chip>
                            </template>

                            <!-- <template v-slot:item.actions="{ item }">
                                <v-icon
                                small
                                class="mr-2"
                                @click="showCourses(item)"
                                >
                                    mdi-bookshelf
                                </v-icon>
                            </template> -->
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <Modal
        :dialog="dialog"
        :title="activitiesData"
        :isSave="isSave"
        @close="dialog = false"
        >
            <template v-slot>
                <v-data-table
                :headers="activitiesHeaders"
                :items="activities"
                :search="activitiesSearch"
                class="mt-3"
                >
                </v-data-table>
            </template>
        </Modal>
    </section>
</template>
<script>
    import {mapMutations,mapState} from "vuex"
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    import Modal from '@/components/Modal.vue'

    export default {
        mixins: [validationMixin],
        data(){
            return {
                isSave: false,
                ciclo: '',
                activitiesSearch: '',
                items: [
                    {value: 77, text: 'Ciclo 2021'}
                ],
                sedes: [],
                sede: '',
                jornada: '',
                jornadas: [],
                level: '',
                levels: [],
                grade: '',
                grades: [],
                section: '',
                sections: [],
                bimestre: '',
                loadSede: false,
                loadJornada: false,
                loadLevel: false,
                loadGrade: false,
                loadSection: false,
                studentsLoad: false,
                exportLoad: false,
                search: '',
                headers: [],
                students: [],
                loadingText: 'Seleccionar Grado',
                bimestres: [],
                loadBimestre: false,
                showSabanas: false,
                finalGrade: false,
                recovery: false,
                activities: [],
                dialog: false,
                activitiesData: '',
                activitiesHeaders: [
                    {
                        text: 'Nombre actividad',
                        value: 'actividad'
                    },
                    {
                        text: 'Nota',
                        value: 'finalgrade'
                    },
                    {
                        text: 'Valor actividad',
                        value: 'rawgrademax'
                    },
                    {
                        text: 'Comentarios',
                        value: 'feedback'
                    }
                ],
            }
        },
        validations: {
            sede: {required},
            jornada: {required},
            level: {required},
            grade: {required},
            section: {required},
            bimestre: {required}
        },
        computed: {
            ...mapState(['schoolCycle']),
            cicloErrors () {
                const errors = []
                if (!this.$v.ciclo.$dirty) return errors
                !this.$v.ciclo.required && errors.push('Elige el ciclo')
                return errors
            },
            sedeErrors () {
                const errors = []
                if (!this.$v.sede.$dirty) return errors
                !this.$v.sede.required && errors.push('Elige la sede')
                return errors
            },
            jornadaErrors () {
                const errors = []
                if (!this.$v.jornada.$dirty) return errors
                !this.$v.jornada.required && errors.push('Elige la jornada')
                return errors
            },
            nivelErrors () {
                const errors = []
                if (!this.$v.level.$dirty) return errors
                !this.$v.level.required && errors.push('Elige el nivel')
                return errors
            },
            gradeErrors () {
                const errors = []
                if (!this.$v.grade.$dirty) return errors
                !this.$v.grade.required && errors.push('Elige el/la grado/carrera')
                return errors
            },
            sectionErrors () {
                const errors = []
                if (!this.$v.section.$dirty) return errors
                !this.$v.section.required && errors.push('Elige la sección')
                return errors
            },
            bimestreErrors () {
                const errors = []
                if (!this.$v.bimestre.$dirty) return errors
                !this.$v.bimestre.required && !this.finalGrade && errors.push('Elige el bimestre')
                return errors
            }
        },
        methods: {
            ...mapMutations(['setLayout','setNotify','setLoading']),
            getDropdownsData(){
                this.loadSede = true
                this.loadJornada = true
                this.loadLevel = true
                this.loadBimestre = true

                this.axios.get('/getdropdownsdata')
                .then( (response) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false
                    this.loadBimestre = false

                    this.sedes = response.data.sedes
                    this.jornadas = response.data.jornadas
                    this.levels = response.data.niveles
                    this.bimestres = response.data.bimestres
                })
                .catch( (error) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false
                    this.loadBimestre = false
                    console.log(error)
                })
            },
            getGrades(){
                this.loadGrade = true

                this.axios.get('/getgrades/'+this.level)
                .then( (response) => {
                    this.loadGrade = false

                    this.grades = response.data.grados
                })
                .catch( (error) => {
                    this.loadGrade = false
                    console.log(error)
                })
            },
            submit(){
                this.$v.$touch()

                if(!this.$v.sede.$invalid
                    && !this.$v.jornada.$invalid
                    && !this.$v.level.$invalid
                    && !this.$v.grade.$invalid
                    && !this.$v.section.$invalid
                ){
                    if(this.finalGrade ||
                        !this.$v.bimestre.$invalid ||
                        this.recovery){
                        this.getSabanasReport()
                    }
                }
            },
            getSabanasReport(){
                this.students = []
                this.headers = []
                this.setLoading(true)
                this.showSabanas = false

                if(this.finalGrade){
                    this.axios.post('/sabanas/average',{
                        sede: this.sede,
                        jornada: this.jornada,
                        level: this.level,
                        grade: this.grade,
                        section: this.section,
                        bimestre: this.bimestre,
                        cycle: this.schoolCycle
                    })
                    .then( (response) => {
                        this.setLoading(false)
                        this.headers = response.data.header
                        this.students = response.data.sabanas

                        if(this.students.length <= 0){
                            this.showSabanas =  false
                            this.setNotify({'show':true,
                                            'color':'primary',
                                            'message':'No se encontró información',
                                            'time':5000})
                            this.loadingText = 'No se encontró información.'
                        }else{
                            this.showSabanas =  true
                        }
                    })
                    .catch( (error) => {
                        this.showSabanas =  false
                        this.studentsLoad = false
                        this.setLoading(false)

                        console.log(error)
                    })
                }else if(this.recovery){
                    this.axios.post('/sabanas/recovery',{
                        sede: this.sede,
                        jornada: this.jornada,
                        level: this.level,
                        grade: this.grade,
                        section: this.section,
                        bimestre: this.bimestre,
                        cycle: this.schoolCycle
                    })
                    .then( (response) => {
                        this.setLoading(false)
                        this.headers = response.data.header
                        this.students = response.data.sabanas

                        if(this.students.length <= 0){
                            this.showSabanas =  false
                            this.setNotify({'show':true,
                                            'color':'primary',
                                            'message':'No se encontró información',
                                            'time':5000})

                            this.loadingText = 'No se encontró información.'
                        }else{
                            this.showSabanas =  true
                        }
                    })
                    .catch( (error) => {
                        this.showSabanas =  false
                        this.setLoading(false)

                        console.log(error)
                    })
                }else{
                    this.axios.post('/sabanas',{
                        sede: this.sede,
                        jornada: this.jornada,
                        level: this.level,
                        grade: this.grade,
                        section: this.section,
                        bimestre: this.bimestre,
                        cycle: this.schoolCycle
                    })
                    .then( (response) => {
                        this.setLoading(false)
                        this.headers = response.data.header
                        this.students = response.data.sabanas
                        this.showSabanas =  true

                        if(this.students.length <= 0){
                            this.showSabanas =  false
                            this.setNotify({'show':true,
                                            'color':'primary',
                                            'message':'No se encontró información',
                                            'time':5000})

                            this.loadingText = 'No se encontró información.'
                        }
                    })
                    .catch( (error) => {
                        this.showSabanas =  false
                        this.setLoading(false)

                        console.log(error)
                    })
                }
            },
            exportExcel(){
                this.$v.$touch()

                var urlExport = ""

                if(!this.$v.sede.$invalid
                    && !this.$v.jornada.$invalid
                    && !this.$v.level.$invalid
                    && !this.$v.grade.$invalid
                    && !this.$v.section.$invalid){
                    if(this.finalGrade){
                        let params =    this.sede +"/"+
                                        this.jornada +"/"+
                                        this.level +"/"+
                                        this.grade +"/"+
                                        this.section +"/"+
                                        this.schoolCycle

                        urlExport = process.env.VUE_APP_PDF_URL + "/exportexcelaverage/" + params
                        window.open(urlExport,'_blank');
                    }else if(this.recovery){
                        let params =    this.sede +"/"+
                                        this.jornada +"/"+
                                        this.level +"/"+
                                        this.grade +"/"+
                                        this.section +"/"+
                                        this.schoolCycle

                        urlExport = process.env.VUE_APP_PDF_URL + "/exportexcelrecovery/" + params
                        window.open(urlExport,'_blank');
                    }else{
                        if(!this.$v.bimestre.$invalid){
                            let params =    this.sede +"/"+
                                            this.jornada +"/"+
                                            this.level +"/"+
                                            this.grade +"/"+
                                            this.section +"/"+
                                            this.schoolCycle +"/"+
                                            this.bimestre

                            var url = process.env.VUE_APP_PDF_URL + "/exportexcel/" + params
                            window.open(url,'_blank');
                        }
                    }
                }
            },
            exportPdf(){
                this.$v.$touch()

                var urlPdf = ""

                if(!this.$v.sede.$invalid
                    && !this.$v.jornada.$invalid
                    && !this.$v.level.$invalid
                    && !this.$v.grade.$invalid){
                    if(this.finalGrade){
                        let params =    this.sede +"/"+
                                        this.jornada +"/"+
                                        this.level +"/"+
                                        this.grade +"/"+
                                        this.section +"/"+
                                        this.schoolCycle

                        urlPdf = process.env.VUE_APP_PDF_URL + "/exportpdfaverage/" + params
                        window.open(urlPdf,'_blank');
                    }else if(this.recovery){
                        let params =    this.sede +"/"+
                                        this.jornada +"/"+
                                        this.level +"/"+
                                        this.grade +"/"+
                                        this.section +"/"+
                                        this.schoolCycle

                        urlPdf = process.env.VUE_APP_PDF_URL + "/exportpdfrecovery/" + params
                        window.open(urlPdf,'_blank');
                    }else{
                        if(!this.$v.bimestre.$invalid){
                            let params =    this.sede +"/"+
                                            this.jornada +"/"+
                                            this.level +"/"+
                                            this.grade +"/"+
                                            this.section +"/"+
                                            this.schoolCycle +"/"+
                                            this.bimestre

                            var url = process.env.VUE_APP_PDF_URL + "/exportpdf/" + params
                            window.open(url,'_blank');
                        }
                    }
                }
            },
            getColor (curso) {
                if(typeof curso !== "undefined") {
                    if (curso.nota >= 60) return 'green'
                    else if (curso.nota < 60 && curso.nota >= 0) return 'red'
                }
            },
            getSections(){
                if(this.level != ''){
                    this.loadSection = true

                    let grades = this.grades.filter((grade) => {
                        return grade.id == this.grade
                    });

                    this.sections = grades[0].sections

                    this.loadSection = false
                }
            },
            getActivities(item,course){
                this.setLoading(true)

                this.axios.post('/getactivitiesbystudentcourse',{
                    'bimestre': this.bimestre,
                    'courseid': course.courseid,
                    'studentid': item.userid,
                    'cycle': this.schoolCycle
                })
                .then( (response) => {
                    this.setLoading(false)

                    this.activitiesData = '['+item.carnet+' - '+item.name+'] '+course.coursename
                    this.activities = response.data.activities
                    this.dialog = true
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            }
        },
        created(){
            this.getDropdownsData()
            this.setLayout('main-layout')
        },
        watch: {
            "level": function(){
                this.grade = ''
                this.section = ''
            },
            "grade": function(){
                if(this.grade != ''){
                    this.section = ''
                    this.sections = []
                    this.getSections()
                }
            },
            "schoolCycle": function(){
                if(this.sede != '' &&
                    this.jornada != '' &&
                    this.level != '' &&
                    this.grade != '' &&
                    this.section != '' &&
                    this.bimestre != ''){
                        this.getSabanasReport()
                    }
            }
        },
        components: {
            Modal
        }
    }
</script>

<style lang="css" scoped>
    .lessTop {margin-top:-20px}
</style>
